import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export abstract class AbstractApiService {

  constructor(protected http: HttpClient) { }

  getUrl() {
    return `${environment.api_url}`;
  }

  validPassword(password: string) {
    const expression = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    return password.match(expression);
  }

  getHeaders() {
    let headers = new HttpHeaders();
    let language = localStorage.getItem('language') || "en-US";
    language = language.replace("-", "_");

    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept-Language', language);

    return { headers: headers };
  }

  getAuthHeaders() {
    let token = localStorage.getItem('token');
    let { headers } = this.getHeaders()

    if (token)
      headers = headers.append('Authorization', `Bearer ${token}`);

    return { headers: headers };
  }
}

export class BaseService extends AbstractApiService { }