<div class="alert">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon color="wf-secondary" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>

  <div class="content">
    <h1 translate>confirm_order.title</h1>
    <wf-icon icon="order-confirmation" type="wf" color="wf-primary large-64"></wf-icon>
    <p translate>confirm_order.description</p>
    <p>
      <span translate>confirm_order.support</span>&nbsp;
      <a href="mailto:info@wanderflare.com">info&#64;wanderflare.com</a>
    </p>
  </div>

  <div class="footer">
    <ion-button (click)="back()" color="wf-secondary" fill="solid" shape="round" size="small" translate>
      confirm_order.back
    </ion-button>
  </div>
</div>