import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { HelpdeskTicketModalComponent } from '../components/helpdesk-ticket-modal/helpdesk-ticket-modal.component';
import { ModalController } from '@ionic/angular';
import { HelpdeskReplyModalComponent } from '../components/helpdesk-reply-modal/helpdesk-reply-modal.component';

@Injectable()
export class HelpdeskService extends BaseService {
  constructor(
    http: HttpClient,
    private modalController: ModalController
  ) {
    super(http);
  }

  listTickets(params: any = {}) {
    let { headers: headers } = this.getAuthHeaders();

    return this.http.get(`${this.getUrl()}/helpdesk`, { headers: headers, params: params })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  getTicket(number: any) {
    let { headers: headers } = this.getAuthHeaders();

    return this.http.get(`${this.getUrl()}/helpdesk/${number}`, { headers: headers, params: {} })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  createTicket(data: any) {
    return this.http.post(`${this.getUrl()}/helpdesk`, { ticket: data }, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  replyTicket(ticket: any, data: any) {
    return this.http.put(`${this.getUrl()}/helpdesk/${ticket.number}`, { reply: data }, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  closeTicket(ticket: any) {
    return this.http.delete(`${this.getUrl()}/helpdesk/${ticket.number}`, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  async openNewTicketModal() {
    const modal = await this.modalController.create({
      component: HelpdeskTicketModalComponent,
      cssClass: "auto-modal"
    });
    modal.present();
  }
}