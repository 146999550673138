import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  toastQueue: any = [];

  constructor(
    private toast: ToastController,
    private translate: TranslateService
  ) { }

  async present(text: string, color: string, queue = false) {
    this.toastQueue.forEach((t: ToastController) => {
      t.dismiss();
    });
    this.toastQueue = [];

    const toast = await this.toast.create({
      message: this.translate.instant(text),
      position: 'bottom',
      duration: 2000,
      color: color,
      buttons: [{
        icon: "close-outline",
        role: 'cancel',
        handler: () => { }
      }]
    });

    queue ? this.toastQueue.push(toast) : false;
    toast.present();
  }
}