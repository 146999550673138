import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { WfIconComponent } from '../wf-icon/wf-icon.component';

@Component({
  selector: 'confirm-order-modal',
  templateUrl: 'confirm-order-modal.component.html',
  styleUrls: ['./confirm-order-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, WfIconComponent, RouterModule],
})
export class ConfirmOrderModalComponent implements OnInit {

  constructor(
    private router: Router,
    private modalController: ModalController,
  ) { }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

  back() {
    this.dismiss();
    this.router.navigate(['/']);
  }
}