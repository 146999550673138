<div class="login">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon color="wf-secondary" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <h1 translate>login.title</h1>
    <h4 translate>login.description</h4>
  </div>

  <form #form="ngForm" (ngSubmit)="submit(form.value)" (keyup.enter)="submit(form.value)">
    <ion-input name="login" ngModel type="email" required [placeholder]="'login.field.email' | translate"></ion-input>

    <ion-input name="password" ngModel type="password" required
      [placeholder]="'login.field.password' | translate"></ion-input>

    <a class="forgot-password" (click)="openRecoverModal(form.value)" translate>login.recovery</a>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>login.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>

    <a class="signup" translate data-dismiss="login-modal" (click)="openSignupModal()">
      login.signup.text <span translate>login.signup.submit</span>
    </a>
  </form>
</div>