import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable()
export class OrderService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  create(params: any, items: any) {
    return this.http.post(`${this.getUrl()}/orders`, {
      order: params,
      items: items
    }, this.getAuthHeaders()).toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }
}