import { Component, OnInit } from '@angular/core';
import { IonApp, IonContent, IonRouterOutlet } from '@ionic/angular/standalone';
import * as ionIcons from 'ionicons/icons';
import { addIcons } from "ionicons";
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, AppHeaderComponent, IonContent],
})
export class AppComponent implements OnInit {
  constructor(
    private toast: ToastController,
    private translate: TranslateService,
    private router: Router
  ) {
    addIcons(ionIcons);

    addIcons({
      'zero_gravity': 'assets/icon/zero_gravity.svg'
    });
  }

  async ngOnInit() {
    this.presentGDPRToast();
  }

  async presentGDPRToast() {
    if (localStorage.getItem('gdpr')) return;

    const toast = await this.toast.create({
      message: this.translate.instant("gdpr.description"),
      position: 'bottom',
      color: "light",
      layout: "stacked",
      buttons: [{
        text: this.translate.instant("gdpr.accept"),
        role: 'cancel',
        handler: () => {
          localStorage.setItem('gdpr', "true");
        },
      }, {
        text: this.translate.instant("gdpr.policy"),
        handler: () => {
          this.router.navigate(['/privacy']);
        }
      }]
    });

    toast.present();
  }
}
