import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WfIconComponent } from '../wf-icon/wf-icon.component';
import { CartService } from 'src/app/services/cart.service';
import { MenuController } from '@ionic/angular/standalone';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule, FormsModule, IonicModule, RouterModule, TranslateModule, WfIconComponent
  ]
})
export class AppHeaderComponent {
  constructor(
    public CustomerService: CustomerService,
    public authService: AuthService,
    public cartService: CartService,
    private menuController: MenuController
  ) { }

  dismiss() {
    this.menuController.close();
  }
}
