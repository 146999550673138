import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable()
export class CityService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  list(params: any) {
    let { headers: headers } = this.getHeaders();

    return this.http.get(`${this.getUrl()}/cities`, { headers: headers, params: params })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }
}