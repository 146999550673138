import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules, withRouterConfig, withInMemoryScrolling } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { AuthService } from './app/services/auth.service';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { ToastService } from './app/services/toast.service';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { APP_INITIALIZER } from '@angular/core';
import { InitService } from './app/services/init.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomerService } from './app/services/customer.service';
import { ProductService } from './app/services/product.service';
import { CityService } from './app/services/city.service';
import { ModalController } from '@ionic/angular';
import { CountryService } from './app/services/country.service';
import { register as registerSwiperElements } from 'swiper/element/bundle';
import { CartService } from './app/services/cart.service';
import { WishlistService } from './app/services/wishlist.service';
import { HelpdeskService } from './app/services/helpdesk.service';
import { OrderService } from './app/services/order.service';

registerSwiperElements();

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initApp(appInitService: InitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ mode: 'ios' }),
    provideRouter(routes, withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }), withPreloading(PreloadAllModules), withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    })),
    provideHttpClient(),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [InitService],
      multi: true
    },
    AuthService,
    CustomerService,
    ToastService,
    ProductService,
    CityService,
    CountryService,
    ModalController,
    CartService,
    WishlistService,
    HelpdeskService,
    OrderService
  ],
});
