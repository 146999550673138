import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { WfIconComponent } from '../wf-icon/wf-icon.component';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'cart-modal',
  templateUrl: 'cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, WfIconComponent, RouterModule],
})
export class CartModalComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    public cartService: CartService
  ) { }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

  remove(uid: any) {
    this.cartService.remove(uid);

    if (!this.cartService.cart.length) {
      this.dismiss();
    }
  }
}