import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from './product.service';
import { CustomerService } from './customer.service';
import { AuthService } from './auth.service';
import { CartService } from './cart.service';
import { WishlistService } from './wishlist.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  allowedLanguages = ['it-IT', 'en-US'];

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private productService: ProductService,
    private wishlistService: WishlistService,
    private CustomerService: CustomerService,
    private authService: AuthService,
    private cartService: CartService,
  ) { }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.platform.ready().then(() => {
        this.setLanguage().then(() => {
          this.productService.listCategories().then(() => {
            this.retrieveUser().then(() => {
              this.cartService.load();
              this.wishlistService.load();

              resolve();
            });
          });
        });
      });
    });
  }

  // Retrieve user data if logged in
  private retrieveUser(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (localStorage.getItem('token')) {
        this.CustomerService.profile().then((response: any) => {
          if (!response.success) {
            this.authService.logout();
          }

          resolve();
        })
      } else {
        resolve();
      }
    });
  }

  // Applies language to i18n from storage
  private setLanguage(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let storedLanguage = localStorage.getItem('language');

      if (storedLanguage && this.allowedLanguages.includes(storedLanguage)) {
        this.translate.setDefaultLang(storedLanguage);
      } else {
        // TODO: Uncomment after enabling languages
        // if (navigator.language === 'it-IT') {
        //   localStorage.setItem("language", 'it-IT');
        //   this.translate.setDefaultLang('it-IT');
        // } else {
        //   localStorage.setItem("language", 'en-US');
        //   this.translate.setDefaultLang('en-US');
        // }

        localStorage.setItem("language", 'en-US');
        this.translate.setDefaultLang('en-US');
      }

      resolve();
    });
  }
}