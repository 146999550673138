import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wf-icon',
  templateUrl: './wf-icon.component.html',
  styleUrls: ['./wf-icon.component.scss'],
  standalone: true,
  imports: [
    CommonModule, FormsModule, IonicModule, RouterModule, TranslateModule
  ]
})
export class WfIconComponent {
  @Input() icon: any;
  @Input() type: any = "category";
  @Input() color: any = "primary";

  constructor() { }
}
