import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable()
export class ProductService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public categories: any = {};
  public childCategories: any = {};

  listOptions(params: any) {
    let { headers: headers } = this.getHeaders();

    return this.http.get(`${this.getUrl()}/options`, { headers: headers, params: params })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  countOptionsByCity(params: any) {
    let { headers: headers } = this.getHeaders();

    return this.http.get(`${this.getUrl()}/options/count_by_city`, { headers: headers, params: params })
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  getOption(id: any) {
    return this.http.get(`${this.getUrl()}/options/${id}`, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  listCategories() {
    return this.http.get(`${this.getUrl()}/product_categories`, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          this.categories = response.product_categories;
          this.childCategories = this.categories.flatMap((p: any) => p.children);
        }

        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }
}