import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'signup-modal',
  templateUrl: 'signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, RouterModule],
})
export class SignupModalComponent {
  constructor(
    public authService: AuthService,
    private CustomerService: CustomerService,
    private modalController: ModalController
  ) { }

  isLoading: boolean = false;
  privacyCheck: boolean = false;
  errors: any = {};

  dismiss() {
    this.modalController.dismiss();
  }

  submit(form: any) {
    if (this.isLoading) return;

    this.isLoading = true;
    this.errors = {};

    if (form.password != form.password_confirm) {
      this.errors.password_confirm = "match";
      this.isLoading = false;
      return;
    }

    if (!this.privacyCheck) {
      this.errors.privacy_check = "privacy_check";
      this.isLoading = false;
      return;
    }

    this.CustomerService.signup(form).then((response: any) => {
      if (response.success) {
        this.modalController.dismiss();
        this.authService.openLoginModal();
      } else {
        this.errors = response.errors;
      }
    }).finally(() => { this.isLoading = false });
  }
}