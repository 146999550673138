import { Injectable } from '@angular/core';

@Injectable()
export class WishlistService {
  constructor() { }

  public wishlist: any = [];

  any(option: any) {
    return this.wishlist.find((i: any) => i.option_id == option.id);
  }

  add(option: any, params: any) {
    const existingItem = this.wishlist.find((i: any) => i.option_id == option.id);

    if (existingItem) {
      existingItem.params.pax += params.pax;
    } else {
      this.wishlist.push({
        option_id: option.id,
        option: option,
        params: params,
      });
    }

    if (existingItem && existingItem.params.pax <= 0) {
      this.wishlist.splice(this.wishlist.indexOf(existingItem), 1);
    }

    this.store();

    return this.wishlist;
  }

  remove(option: any) {
    const existingItem = this.wishlist.find((i: any) => i.option_id == option.id);

    if (existingItem) {
      this.wishlist.splice(this.wishlist.indexOf(existingItem), 1);
    }

    this.store();
    return this.wishlist;
  }

  load() {
    let data = localStorage.getItem('wishlist');
    if (data) {
      this.wishlist = JSON.parse(data);
    } else {
      this.wishlist = [];
      this.store();
    }
  }

  store() {
    localStorage.setItem('wishlist', JSON.stringify(this.wishlist));
  }
}