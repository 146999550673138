import { Injectable } from '@angular/core';
import { ConfirmOrderModalComponent } from '../components/confirm-order-modal/confirm-order-modal.component';
import { ModalController } from '@ionic/angular';
import { CartModalComponent } from '../components/cart-modal/cart-modal.component';

@Injectable()
export class CartService {
  constructor(
    private modalController: ModalController
  ) { }

  public cart: any = [];

  empty() {
    this.cart = [];
    this.store();
  }

  add(option: any, params: any) {
    this.cart.push({
      uid: this.uuid4(),
      option_id: option.id,
      option: option,
      params: params,
    });

    this.store();
    return this.cart;
  }

  update(item: any, params: any) {
    const existingItem = this.cart.find((i: any) => i.uid == item.uid);

    if (existingItem) {
      existingItem.params = params;
    }

    this.store();
    return this.cart;
  }

  remove(uid: any) {
    const existingItem = this.cart.find((i: any) => i.uid == uid);

    if (existingItem) {
      this.cart.splice(this.cart.indexOf(existingItem), 1);
    }

    this.store();
    return this.cart;
  }

  load() {
    let data = localStorage.getItem('cart');
    if (data) {
      this.cart = JSON.parse(data);
    } else {
      this.cart = [];
      this.store();
    }
  }

  subtotal(item: any) {
    let price = item.option.product.slot_type == 'time_based' ? item.params.slot.pax_price : item.option.pax_price;
    return price * item.params.pax;
  }

  total() {
    if (!this.cart || !this.cart.length) return;
    return this.cart.map((c: any) => this.subtotal(c)).reduce((s: any, a: any) => s + a, 0);
  }

  store() {
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  uuid4() {
    return Math.random().toString(16).slice(2) + (new Date()).getTime() + Math.random().toString(16).slice(2);
  }

  async openConfirmModal() {
    const modal = await this.modalController.create({
      component: ConfirmOrderModalComponent,
      cssClass: "auto-modal",
    });
    modal.present();
  }

  async openCartModal() {
    this.modalController.dismiss();

    const modal = await this.modalController.create({
      component: CartModalComponent,
      cssClass: "lateral-modal",
    });
    modal.present();
  }
}