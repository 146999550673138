<div class="recover">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon color="wf-secondary" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <h1 translate>recover.title</h1>
    <p>{{ 'recover.step_' + this.step | translate }}</p>
  </div>

  <!-- Step 0 (email address) -->
  <form #form="ngForm" (ngSubmit)="requestToken(form.value)" (keyup.enter)="requestToken(form.value)" *ngIf="step == 0">
    <ion-input name="email" [(ngModel)]="email" type="email" required
      [placeholder]="'recover.field.email' | translate"></ion-input>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>recover.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>

    <a class="login" translate data-dismiss="login-modal" (click)="openLoginModal()" translate>
      recover.login
    </a>
  </form>

  <!-- Step 1 (validate token) -->
  <form #form="ngForm" (ngSubmit)="verifyToken(form.value)" (keyup.enter)="verifyToken(form.value)" *ngIf="step == 1">
    <ion-input min="5" max="5" name="token" ngModel type="text" required
      [placeholder]="'recover.field.token' | translate"></ion-input>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>recover.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>

    <a class="login" translate data-dismiss="login-modal" (click)="openLoginModal()" translate>
      recover.login
    </a>
  </form>

  <!-- Step 2 (change password) -->
  <form #form="ngForm" (ngSubmit)="changePassword(form.value)" (keyup.enter)="changePassword(form.value)"
    *ngIf="step == 2">
    <ion-input name="password" ngModel type="password" required
      [placeholder]="'recover.field.password' | translate"></ion-input>

    <ion-input name="password_repeat" ngModel type="password" required
      [placeholder]="'recover.field.password_repeat' | translate"></ion-input>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>recover.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>

    <a class="login" translate data-dismiss="login-modal" (click)="openLoginModal()" translate>
      recover.login
    </a>
  </form>
</div>