<div class="signup">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon color="wf-secondary" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <h1 translate>login.title</h1>
    <h4 translate>login.description</h4>
  </div>

  <form #form="ngForm" (ngSubmit)="submit(form.value)" (keyup.enter)="submit(form.value)">
    <ion-input name="first_name" ngModel type="text" required
      [placeholder]="'signup.field.first_name' | translate"></ion-input>
    <ion-note color="danger" translate *ngIf="errors.first_name">signup.error.{{ errors.first_name }}</ion-note>

    <ion-input name="last_name" ngModel type="text" required
      [placeholder]="'signup.field.last_name' | translate"></ion-input>
    <ion-note color="danger" translate *ngIf="errors.last_name">signup.error.{{ errors.last_name }}</ion-note>

    <ion-input name="email" ngModel type="email" required [placeholder]="'signup.field.email' | translate"></ion-input>
    <ion-note color="danger" translate *ngIf="errors.email">signup.error.{{ errors.email }}</ion-note>
    <ion-note color="danger" translate *ngIf="errors.login">signup.error.{{ errors.login }}</ion-note>

    <ion-input name="password" ngModel type="password" required
      [placeholder]="'signup.field.password' | translate"></ion-input>
    <ion-note color="danger" translate *ngIf="errors.password">signup.error.{{ errors.password }}</ion-note>

    <ion-input name="password_confirm" ngModel type="password" required
      [placeholder]="'signup.field.password_confirm' | translate"></ion-input>
    <ion-note color="danger" translate
      *ngIf="errors.password_confirm">signup.error.{{errors.password_confirm}}</ion-note>

    <ion-toggle name="privacy" label-placement="end" color="wf-secondary" required [(ngModel)]="privacyCheck">
      <span translate>signup.field.privacy</span>&nbsp;
      <a target="_blank" href="/privacy" translate>signup.field.privacy_link</a>
    </ion-toggle>

    <ion-note color="danger" translate *ngIf="errors.privacy_check">signup.error.privacy_check</ion-note>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>signup.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>

    <a class="login" translate data-dismiss="login-modal" (click)="authService.openLoginModal()">
      signup.login.text <span translate>signup.login.submit</span>
    </a>
  </form>
</div>