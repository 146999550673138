import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  { path: 'profile', loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage), canActivate: [AuthGuard] },
  { path: 'profile/confirm/:token', loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage), canActivate: [AuthGuard] },
  { path: 'wishlist', loadComponent: () => import('./pages/wishlist/wishlist.page').then(m => m.WishlistPage), canActivate: [AuthGuard] },

  { path: 'support', loadComponent: () => import('./pages/helpdesk/helpdesk.page').then(m => m.HelpdeskPage), canActivate: [AuthGuard] },
  { path: 'support/:number', loadComponent: () => import('./pages/helpdesk/ticket/ticket.page').then(m => m.HelpdeskTicketPage), canActivate: [AuthGuard] },

  { path: 'products', loadComponent: () => import('./pages/products/list/products.page').then(m => m.ProductsPage) },
  { path: 'products/:slug', loadComponent: () => import('./pages/products/show/product.page').then(m => m.ProductPage) },

  { path: 'cart', loadComponent: () => import('./pages/cart/cart.page').then(m => m.CartPage) },
  { path: 'about', loadComponent: () => import('./pages/about/about.page').then(m => m.AboutPage) },
  { path: 'privacy', loadComponent: () => import('./pages/privacy/privacy.page').then(m => m.PrivacyPage) },
  { path: 'contact-us', loadComponent: () => import('./pages/contact/contact.page').then(m => m.ContactPage) },

  { path: 'home', loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage), runGuardsAndResolvers: 'always' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];
