import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'login-modal',
  templateUrl: 'login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, RouterModule],
})
export class LoginModalComponent {
  constructor(private authService: AuthService,
    private toast: ToastService,
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router
  ) { }

  isLoading: boolean = false;

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

  public async openSignupModal() {
    this.modalController.dismiss();
    this.authService.openSignupModal();
  }

  public async openRecoverModal(form: any) {
    this.modalController.dismiss();
    this.authService.openRecoverModal(form);
  }

  submit(form: any) {
    if (this.isLoading) return;

    this.isLoading = true;

    this.authService.login(form).then((response: any) => {
      if (response.success) {
        this.modalController.dismiss();
        this.redirect();
      } else {
        this.toast.present("login.error.generic", "danger");
      }
    }).finally(() => { this.isLoading = false });
  }

  redirect() {
    let path = this.navParams.get('path');

    if (path) {
      this.router.navigate([path]);
    }
  }
}