import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CustomerService } from './customer.service';
import { ModalController } from '@ionic/angular';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';
import { SignupModalComponent } from '../components/signup-modal/signup-modal.component';
import { RecoverPasswordModalComponent } from '../components/recover-password-modal/recover-password-modal.component';

@Injectable()
export class AuthService extends BaseService {

  constructor(
    http: HttpClient,
    private CustomerService: CustomerService,
    private modalCtrl: ModalController
  ) {
    super(http);
  }

  login(data: any) {
    return this.http.post(`${this.getUrl()}/login`, data, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          this.CustomerService.customer = response.customer;
          localStorage.setItem('token', response.jwt);
        }

        return response;
      })
      .catch((error: any) => {
        return error
      });
  }

  signup(data: any) {
    return this.http.post(`${this.getUrl()}/signup`, data, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  }

  requestPasswordToken(params: any) {
    let { headers: headers } = this.getHeaders();

    return this.http.get(`${this.getUrl()}/recover_password`, { headers: headers, params: params })
      .toPromise()
      .then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  verifyPasswordToken(params: any) {
    return this.http.post(`${this.getUrl()}/recover_password`, params, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  recoverPassword(params: any) {
    return this.http.put(`${this.getUrl()}/recover_password`, params, this.getHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  logout() {
    this.CustomerService.customer = {};
    localStorage.removeItem('token');
    window.location.reload();
  }

  loggedIn() {
    return localStorage.getItem('token') && this.CustomerService.customer;
  }

  public async openLoginModal(props: any = {}) {
    const modal = await this.modalCtrl.create({
      component: LoginModalComponent,
      cssClass: "auto-modal",
      componentProps: props
    });
    modal.present();
  }

  public async openSignupModal() {
    const modal = await this.modalCtrl.create({
      component: SignupModalComponent,
      cssClass: "auto-modal"
    });
    modal.present();
  }


  public async openRecoverModal(form: any) {
    const modal = await this.modalCtrl.create({
      component: RecoverPasswordModalComponent,
      cssClass: "auto-modal",
      componentProps: { email: form.login }
    });
    modal.present();
  }
}