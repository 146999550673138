import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { HelpdeskService } from 'src/app/services/helpdesk.service';

@Component({
  selector: 'helpdesk-ticket-modal',
  templateUrl: 'helpdesk-ticket-modal.component.html',
  styleUrls: ['./helpdesk-ticket-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, RouterModule],
})
export class HelpdeskTicketModalComponent {
  constructor(
    private helpdeskService: HelpdeskService,
    private toast: ToastService,
    private modalController: ModalController,
    private router: Router
  ) { }

  isLoading: boolean = false;
  orders: any = [];

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

  submit(form: any) {
    if (this.isLoading) return;

    this.isLoading = true;

    this.helpdeskService.createTicket(form).then((response: any) => {
      if (response.success) {

        this.router.navigate(['/support', response.ticket.number])
      } else {
        this.toast.present("helpdesk.create.error", "danger");
      }
    }).finally(() => {
      this.dismiss();
      this.isLoading = false;
    });
  }
}