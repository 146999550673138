import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'recover-password-modal',
  templateUrl: 'recover-password-modal.component.html',
  styleUrls: ['./recover-password-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, RouterModule],
})
export class RecoverPasswordModalComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    this.step = 0;
  }

  email: any;
  isLoading: boolean = false;
  step: number = 0;
  token: any;

  ngOnInit() {
    if (this.navParams.get('email')) {
      this.email = this.navParams.get('email');
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  openLoginModal() {
    this.dismiss();
    this.authService.openLoginModal();
  }

  requestToken(form: any) {
    if (this.isLoading) return;
    this.isLoading = true;

    let lastVerifyEmail = localStorage.getItem('last_reset_email');
    if (lastVerifyEmail) {
      let oldDate: any = new Date(lastVerifyEmail);
      let currentDate: any = new Date();

      if (currentDate - oldDate < 10 * 60 * 1000) {
        this.step = 1;
        this.isLoading = false;
        return;
      };
    }

    this.authService.requestPasswordToken({ email: this.email }).then((response: any) => {
      this.step = 1;
      localStorage.setItem('last_reset_email', new Date().toString());
    }).finally(() => { this.isLoading = false });
  }

  verifyToken(form: any) {
    if (this.isLoading) return;
    this.isLoading = true;

    this.authService.verifyPasswordToken({ email: this.email, token: form.token }).then((response: any) => {
      if (response.success) {
        this.token = form.token;
        this.step = 2;
      } else {
        this.toast.present("recover.errors.token", "danger");
      }
    }).finally(() => { this.isLoading = false });
  }

  changePassword(form: any) {
    if (this.isLoading) return;
    this.isLoading = true;

    if (form.password != form.password_repeat) {
      this.toast.present("recover.errors.password_repeat", "danger");
      return;
    }

    this.authService.recoverPassword({ email: this.email, password: form.password, token: this.token }).then((response: any) => {
      if (response.success) {
        this.dismiss();
        this.authService.openLoginModal();
      } else {
        this.toast.present("recover.errors.password", "danger");
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }
}