<ion-header>
  <ion-toolbar>
    <div class="header">
      <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>

      <div class="logo-container">
        <a routerLink="/home" (click)="dismiss()">
          <img class="logo" src="assets/logo/logo-white.svg" alt="Logo">
        </a>
      </div>

      <div class="actions">
        <ion-buttons slot="end">
          <ion-button class="cart-badge" (click)="dismiss()" routerLink="/cart">
            <wf-icon [icon]="cartService.cart.length > 0 ? 'cart-fill' : 'cart-outline'" type="wf" color="light" />
            <ion-badge color="wf-secondary" *ngIf="cartService.cart.length > 0">
              {{ cartService.cart.length }}
            </ion-badge>
          </ion-button>

          <ion-button id="user-dropdown" *ngIf="CustomerService.loggedIn()">
            <wf-icon icon="profile-outline" type="wf" color="light"></wf-icon>
          </ion-button>

          <ion-popover trigger="user-dropdown" [dismissOnSelect]="true" *ngIf="CustomerService.loggedIn()">
            <ng-template>
              <ion-content>
                <ion-list>
                  <ion-item [button]="true" [detail]="false" (click)="dismiss()" routerLink="/wishlist"
                    translate>header.menu.wishlist</ion-item>
                  <ion-item [button]="true" [detail]="false" (click)="dismiss()" routerLink="/profile"
                    translate>header.menu.profile</ion-item>
                  <ion-item [button]="true" [detail]="false" (click)="dismiss()" routerLink="/support"
                    translate>header.menu.helpdesk</ion-item>
                  <ion-item [button]="true" [detail]="false" (click)="authService.logout()"
                    translate>header.menu.logout</ion-item>
                </ion-list>
              </ion-content>
            </ng-template>
          </ion-popover>
        </ion-buttons>

        <ion-button (click)="authService.openLoginModal()" color="wf-secondary" fill="solid" shape="round" size="small"
          translate *ngIf="!CustomerService.loggedIn()">
          header.menu.login
        </ion-button>
      </div>
    </div>
  </ion-toolbar>
</ion-header>


<ion-menu menu-id="menu" content-id="main-content">
  <ion-content>
    <ul>
      <li>
        <a routerLink="/about" (click)="dismiss()" translate>header.menu.about_us</a>
        <ion-icon name="chevron-forward-outline"></ion-icon>

      </li>

      <li>
        <a href="https://wanderflare.blog/" target="_blank" (click)="dismiss()" translate>header.menu.blog</a>
        <ion-icon name="chevron-forward-outline"></ion-icon>

      </li>

      <li>
        <a routerLink="/contact-us" (click)="dismiss()" translate>header.menu.contact_us</a>
        <ion-icon name="chevron-forward-outline"></ion-icon>

      </li>
    </ul>

    <a href="https://landing.wanderflare.com/join-us.html" target="_blank" (click)="dismiss()"
      translate>header.menu.partner</a>

    <div class="social-links">
      <a href="https://www.facebook.com/wanderflare" target="_blank">
        <ion-icon name="logo-facebook" />
      </a>

      <a href="https://www.instagram.com/wanderflare" target="_blank">
        <ion-icon name="logo-instagram" />
      </a>

      <a href="https://www.linkedin.com/company/wanderflare/" target="_blank">
        <ion-icon name="logo-linkedin" />
      </a>

      <a href="https://www.tiktok.com/@wanderflare" target="_blank">
        <ion-icon name="logo-tiktok" />
      </a>
    </div>
  </ion-content>
</ion-menu>