<div class="cart-modal">
  <div class="header">
    <ion-icon name="close-outline" (click)="dismiss()" color="dark"></ion-icon>
  </div>

  <div class="scroll-view" *ngIf="cartService.cart">
    <div class="item" *ngFor="let item of cartService.cart">
      <div class="option">
        <div class="qty">
          {{item.params.pax}}x
        </div>
        <div class="description">
          <h4>{{item.option.product.title}}</h4>
          <p>{{item.option.title}}</p>
          <h4>{{cartService.subtotal(item) | currency: 'EUR' }}</h4>
        </div>
      </div>

      <ion-icon name="trash-outline" color="dark" (click)="remove(item.uid)"></ion-icon>
    </div>
  </div>

  <div class="footer">
    <ion-button size="large" expand="block" fill="outline" shape="round" color="wf-primary" routerLink="/cart"
      (click)="dismiss()" translate>
      cart.go_to_cart
    </ion-button>

    <ion-button size="large" expand="block" fill="outline" shape="round" color="dark" (click)="dismiss()" translate>
      cart.continue_shopping
    </ion-button>
  </div>
</div>