<div class="ticket">
  <div class="header">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon color="wf-secondary" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <h1 translate>helpdesk.create.title</h1>
  </div>

  <form #form="ngForm" (ngSubmit)="submit(form.value)" (keyup.enter)="submit(form.value)">
    <ion-label translate>helpdesk.field.name</ion-label>
    <ion-input name="name" ngModel type="text" required
      [placeholder]="'helpdesk.placeholder.name' | translate"></ion-input>

    <ion-label translate>helpdesk.field.description</ion-label>
    <ion-textarea name="description" rows="6" ngModel required
      [placeholder]="'helpdesk.placeholder.description' | translate"></ion-textarea>

    <ion-label translate *ngIf="orders.length">helpdesk.field.order</ion-label>
    <ion-select name="order" ngModel *ngIf="orders.length" [placeholder]="'helpdesk.placeholder.order' | translate">
      <ion-select-option *ngFor="let order of orders" [value]="order">{{ order.uid }}</ion-select-option>
    </ion-select>

    <ion-button type="submit" expand="block" color="wf-secondary" shape="round" size="default"
      [disabled]="isLoading || !form.valid">
      <span *ngIf="!isLoading" translate>helpdesk.create.submit</span>
      <ion-spinner name="circles" *ngIf="isLoading"></ion-spinner>
    </ion-button>
  </form>
</div>