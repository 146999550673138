import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable()
export class CustomerService extends BaseService {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public customer: any = null;

  signup(data: any) {
    return this.http.post(`${this.getUrl()}/customers`, data, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  profile() {
    return this.http.get(`${this.getUrl()}/customers`, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          this.customer = response.customer;
        }

        return response;
      })
      .catch((error: any) => {
        return error.error;
      });
  }

  update(params: any) {
    return this.http.put(`${this.getUrl()}/customers`, params, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          this.customer = response.customer;
        }

        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  verifyEmail(params: any) {
    return this.http.put(`${this.getUrl()}/customers/email_token`, params, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        if (response.success) {
          this.customer.email_verified = true;
        }
        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  requestEmailToken() {
    return this.http.get(`${this.getUrl()}/customers/email_token`, this.getAuthHeaders())
      .toPromise()
      .then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error.error;
      });
  }

  loggedIn() {
    return this.customer != null;
  }
}